import { useSelector } from "react-redux";

import { RootState } from "../../../store";
import { translate } from "../../../lang";

import "./Navbar.scss";

const Navbar = () => {
  const { language } = useSelector((state: RootState) => state.lang);

  return (
    <ul className="navbar">
      <li className={"home--link"}>
        <a href="/" >
          {translate("menuHome", language)}
        </a>
      </li>
      <li className={"about--link"}>
        <a href="/about" >
          {translate("menuAbout", language)}
        </a>
      </li>
      <li className={"experience--link"}>
        <a href="/experience" >
          {translate("menuExperience", language)}
        </a>
      </li>
    </ul>
  );
};

export default Navbar;
