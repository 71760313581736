import React from "react";

const Email = () => {
  return (
    <svg
      id="email"
      width="69"
      height="69"
      viewBox="0 0 69 69"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.5 0C15.456 0 0 15.456 0 34.5C0 53.544 15.456 69 34.5 69H51.75V62.1H34.5C19.527 62.1 6.9 49.473 6.9 34.5C6.9 19.527 19.527 6.9 34.5 6.9C49.473 6.9 62.1 19.527 62.1 34.5V39.4335C62.1 42.159 59.6505 44.85 56.925 44.85C54.1995 44.85 51.75 42.159 51.75 39.4335V34.5C51.75 24.978 44.022 17.25 34.5 17.25C24.978 17.25 17.25 24.978 17.25 34.5C17.25 44.022 24.978 51.75 34.5 51.75C39.261 51.75 43.608 49.818 46.713 46.6785C48.9555 49.749 52.8195 51.75 56.925 51.75C63.7215 51.75 69 46.23 69 39.4335V34.5C69 15.456 53.544 0 34.5 0ZM34.5 44.85C28.773 44.85 24.15 40.227 24.15 34.5C24.15 28.773 28.773 24.15 34.5 24.15C40.227 24.15 44.85 28.773 44.85 34.5C44.85 40.227 40.227 44.85 34.5 44.85Z"
      />
    </svg>
  );
};
export default Email;
