import React, { FC } from "react";
import { useSelector } from "react-redux";

import { translate } from "../../../lang";
import { RootState } from "../../../store";
import Info from "../../atoms/info";

import "./About.scss";

const About: FC = () => {
  const { language } = useSelector((state: RootState) => state.lang);

  return (
    <>
      <section id="about" className="about">
        <div className="about__container">
          <div className="about__content">
            <h2>
              {translate("aboutTitle", language)}
              <Info />
              <span>{translate("aboutTitleExplain", language)}</span>
            </h2>
            <p>
              {translate("aboutContent", language)}
              <br />
              <br />
              {translate("aboutLikeThings", language)}
            </p>
          </div>
          <blockquote className="about-citation" cite="">
            {translate("aboutCitation", language)}
          </blockquote>
        </div>
      </section>
    </>
  );
};

export default About;
