import React, {useEffect, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../store";
import { setLanguage } from "../../../store/actions/langActions";

import './Languages.scss';

const Languages = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state: RootState) => state.lang);
  const langList = useRef<HTMLUListElement>(null);

  useEffect(() => {
    langList.current?.querySelectorAll('.active').forEach(el => el.classList.remove(`active`))
    langList.current?.querySelector(`#${language.toLowerCase()}`)?.classList.add('active');
  }, [language]);

  const chooseLanguageHandler = (value: string) => {
    dispatch(setLanguage(value));
  };

  return (
    <div className="languages">
        <ul className="languages__list" ref={langList}>
          <li id="en" className="languages__list--item" onClick={() => chooseLanguageHandler("EN")}>En</li>
          <li id="es" className="languages__list--item" onClick={() => chooseLanguageHandler("ES")}>Es</li>
          <li id="pt" className="languages__list--item" onClick={() => chooseLanguageHandler("PT")}>Pt</li>
        </ul>
    </div>
  );
};

export default Languages;
