import React, { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { translCompanies } from "../../../lang";
import { RootState } from "../../../store";

import CardCompany from "../../molecules/CardCompany/CardCompany";

import "./CompanyExps.scss";

const CompanyExps: FC = () => {
  const { language } = useSelector((state: RootState) => state.lang);

  const companiesList = useRef<HTMLUListElement>(null);
  const [companies, setCompanies] = useState(translCompanies(language));

  useEffect(() => {
    setCompanies(translCompanies(language));
  }, [language]);

  return (
    <ul className="companies__list" ref={companiesList}>
      {companies && (
        <>
          <CardCompany
            informations={{
              title: companies[2].name,
              time: companies[2].time,
              positions: companies[2].positions,
              class: "",
            }}
          />
          <CardCompany
            informations={{
              title: companies[1].name,
              time: companies[1].time,
              positions: companies[1].positions,
              class: "",
            }}
          />
          <CardCompany
            informations={{
              title: companies[0].name,
              time: companies[0].time,
              positions: companies[0].positions,
              class: "",
            }}
          />
        </>
      )}
    </ul>
  );
};

export default CompanyExps;
