
import React, { FC, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import './App.scss';

// import scrollActions from './components/Common/scrollActions';

import Page404 from './pages/404';
import Home from './pages/Home/Home';

const App: FC = () => {
  useEffect(() => {
    // scrollActions.scrollWatchForAbout();
    // scrollActions.scrollWatchForNavChange();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home position={'/'} />} />
        <Route path="/about" element={<Home position={"about"} />} />
        <Route path="/experience" element={<Home position={"experience"} />} />
        <Route path="/images/email.png" element={`${process.env.PUBLIC_URL}/images/email.png`}/>
        <Route path="/images/git.png" element={`${process.env.PUBLIC_URL}/images/git.png`}/>
        <Route path="/images/ln.png" element={`${process.env.PUBLIC_URL}/images/ln.png`}/>
        <Route path="/images/Info.png" element={`${process.env.PUBLIC_URL}/images/Info.png`}/>
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Router>
  );
}

export default App;