import React from "react";

const LinkedIn = () => {
  return (
    <svg
    id="linkedIn-logo"
      width="69"
      height="69"
      viewBox="0 0 69 69"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.6301 24.0431H38.4433V30.4256C40.2891 26.7548 45.0225 23.4566 52.133 23.4566C65.7639 23.4566 69 30.7637 69 44.1704V69H55.2V47.2236C55.2 39.5887 53.3543 35.2832 48.6554 35.2832C42.1383 35.2832 39.4301 39.9234 39.4301 47.2202V69H25.6301V24.0431V24.0431ZM1.9665 68.4135H15.7665V23.4566H1.9665V68.4135V68.4135ZM17.7434 8.7975C17.7439 9.9542 17.5145 11.0995 17.0685 12.1667C16.6225 13.234 15.9688 14.202 15.1455 15.0144C13.4771 16.6726 11.2187 17.6007 8.8665 17.595C6.51839 17.5934 4.26527 16.6676 2.5944 15.0179C1.77403 14.2027 1.12258 13.2336 0.677386 12.1662C0.23219 11.0988 0.001998 9.95402 0 8.7975C0 6.46185 0.9315 4.22625 2.59785 2.57715C4.26725 0.925147 6.52133 -0.00103228 8.86995 8.63413e-07C11.2229 8.63413e-07 13.4791 0.928051 15.1455 2.57715C16.8084 4.22625 17.7434 6.46185 17.7434 8.7975Z"
      />
    </svg>
  );
};
export default LinkedIn;
