import React, { FC } from "react";

import Languages from "../../molecules/Languages/Languages";
import Links from "../../molecules/Links/Links";

import "./Footer.scss";

const Footer: FC = () => {
  return (
    <footer className="footer">
      <Links />
      <Languages />
    </footer>
  );
};

export default Footer;
