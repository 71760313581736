import React, { FC } from "react";
// import { useSelector } from "react-redux";

// import { translateCompanies } from "../../../lang";
// import { RootState } from "../../../store";

import CompanyExps from "../../organisms/CompanyExps/CompanyExps";
// import Info from "../../atoms/info";

import "./Experience.scss";

const Experience: FC = () => {
  // const { language } = useSelector((state: RootState) => state.lang);
  
  // const experiencesList = useRef<HTMLUListElement>(null);
  // const [experiences, setExperiences] = useState(translateExperience(language));

  // useEffect(() => {
  //   setExperiences(translateExperience(language))
  // }, [language]);

  return (
    <>
      <section id="experience" className="experience">
        <div className="experience__container">
          <CompanyExps />
        </div>
      </section>
    </>
  );
};

export default Experience;
