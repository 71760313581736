import React, { FC, useEffect } from "react";

import { useSelector } from "react-redux";

import { translate } from "../../../lang";
import { RootState } from "../../../store";

import "./Intro.scss";

const Intro: FC = () => {
  const { language } = useSelector((state: RootState) => state.lang);

  useEffect(() => {
    const welcomeText = translate("name", language).split("");
    const elH1 = document.querySelector("h1") as HTMLElement;
    elH1.innerHTML = "";

    welcomeText.forEach((letter, index) => {
      setTimeout(() => {
        if (elH1) elH1.innerHTML += `${letter}`;
      }, index * 30);
    });
  }, [language]);

  return (
    <section id="intro" className="intro">
      <div className="intro__header">
        <h1>{translate("name", language)}</h1>
        <h2>{translate("work", language)}</h2>
      </div>
    </section>
  );
};

export default Intro;
