import React, { FC, useEffect, useState } from "react";

import Header from "../../components/templates/Header/Header";
import Footer from "../../components/organisms/Footer/Footer";

import Intro from "../../components/templates/Intro/Intro";
import About from "../../components/templates/About/About";
import Experience from "../../components/templates/Experience/Experience";

import scrollActions from "../../components/atoms/scrollActions";

import "./Home.scss";

interface IHomeProps {
  position: string;
}

const Home: FC<IHomeProps> = ({ position }) => {
  const [hash, setHash] = useState("intro");

  const onScroll = (deltaY: number) => {
    if (scrollActions.handle) clearTimeout(scrollActions.handle);

    scrollActions.scrollY = scrollActions.scrollY + deltaY;

    const changeTo = scrollActions.getNextOrPrevElment();

    if (changeTo !== false)
      scrollActions.handle = setTimeout(() => {
        scrollActions.scrollY = 0;
        setHash(changeTo);
      }, 200);
  };

  useEffect(() => {
    scrollActions.scrollEvents(onScroll);
  }, []);

  useEffect(() => {
    if (position === "/") {
      scrollActions.changeHomeView("intro");
      setHash("intro");
    } else {
      scrollActions.changeHomeView(`${position}`);
      setHash(`${position}`);
    }
  }, [position]);

  useEffect(() => {
    scrollActions.changeHomeView(hash);
  }, [hash]);

  return (
    <>
      <Header />
      <Intro />
      <About />
      <Experience />
      <Footer />
    </>
  );
};

export default Home;
