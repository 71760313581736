import { useSelector } from "react-redux";

import { RootState } from "../../../store";
import { translate } from "../../../lang";

import GitHub from "../../atoms/github";
import Email from "../../atoms/email";
import LinkedIn from "../../atoms/linkedin";
import "./Links.scss";

const Links = () => {
  const { language } = useSelector((state: RootState) => state.lang);

  return (
    <div className="links">
      <ul className="links__list">
        <li id="linkedin" className="links__list--item">
          <a href={translate("linkedin", language)} rel="noreferrer" target="_blank">
            <LinkedIn />
          </a>
        </li>
        <li id="github" className="links__list--item">
          <a href={translate("github", language)} rel="noreferrer" target="_blank">
            <GitHub />
          </a>
        </li>
        <li id="email" className="links__list--item">
          <a href={`mailto:${translate("email", language)}`}>
          <Email />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Links;
