import React from "react";

const Info = () => {
  return (
    <svg
      width="69"
      height="69"
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="bi:info-circle-fill" clipPath="url(#clip0_8_2)">
        <path
          id="Vector"
          d="M34.5 69C43.65 69 52.4252 65.3652 58.8952 58.8952C65.3652 52.4252 69 43.65 69 34.5C69 25.35 65.3652 16.5748 58.8952 10.1048C52.4252 3.63481 43.65 0 34.5 0C25.35 0 16.5748 3.63481 10.1048 10.1048C3.63481 16.5748 0 25.35 0 34.5C0 43.65 3.63481 52.4252 10.1048 58.8952C16.5748 65.3652 25.35 69 34.5 69V69ZM38.5106 28.4107L34.1981 48.7011C33.8962 50.1673 34.3232 50.9996 35.5091 50.9996C36.3458 50.9996 37.6093 50.6977 38.4675 49.9387L38.088 51.7328C36.8503 53.2249 34.1205 54.3116 31.7702 54.3116C28.7385 54.3116 27.4491 52.4917 28.2857 48.6234L31.4683 33.6677C31.7443 32.4041 31.4942 31.947 30.2306 31.6408L28.2857 31.2915L28.6393 29.6484L38.5149 28.4107H38.5106ZM34.5 23.7188C33.3563 23.7188 32.2594 23.2644 31.4506 22.4556C30.6419 21.6469 30.1875 20.55 30.1875 19.4062C30.1875 18.2625 30.6419 17.1656 31.4506 16.3569C32.2594 15.5481 33.3563 15.0938 34.5 15.0938C35.6437 15.0938 36.7406 15.5481 37.5494 16.3569C38.3581 17.1656 38.8125 18.2625 38.8125 19.4062C38.8125 20.55 38.3581 21.6469 37.5494 22.4556C36.7406 23.2644 35.6437 23.7188 34.5 23.7188V23.7188Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8_2">
          <rect width="69" height="69" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Info;
