import React, { FC, useEffect, useRef } from "react";

import "./CardCompany.scss";

interface IPositions {
  name: string;
  desc: string;
}

interface informations {
  title: string;
  time: string;
  class: string;
  positions: IPositions[];
}
interface ICardProps {
  informations: informations;
}

const CardCompany: FC<ICardProps> = ({ informations }) => {
  const list = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (list.current) list.current.innerHTML = "";

    informations.positions.forEach((value, index) => {
      const titlePosition = document.createElement("h4");
      titlePosition.className = "position__name";
      titlePosition.innerHTML = `${value.name}`;

      const descPosition = document.createElement("p");
      descPosition.className = "position__desc";
      descPosition.innerHTML = value.desc;

      const li = document.createElement("li");
      li.id = `${informations.title}-${index}`;
      li.className = "company__positions--item";

      li.append(titlePosition);
      li.append(descPosition);

      list.current?.prepend(li);
    });
  }, [informations]);

  return (
    <li className="company">
        <h3 className="company__title">{informations.title}</h3>
        <p className="company__time">{informations.time}</p>
        <ul className="company__positions" ref={list}></ul>
    </li>
  );
};

export default CardCompany;
