import React from "react";
import { Link } from "react-router-dom";

import Logo from "../../atoms/logo";
import Navbar from "../../organisms/Navbar/Navbar";

import "./Header.scss";

const Header = () => {
  return (
    <header className="header">
      <div className="header__nav">
        <div className="header__brand">
            <Link to="/">
              <Logo />
            </Link>
        </div>
        <div className="header__nav-menu">
          <Navbar />
        </div>
      </div>
    </header>
  );
};

export default Header;
