import en from './en/generic.json';
import pt from './pt/generic.json';
import es from './es/generic.json';

import enCompanies from './en/companies.json';
import ptCompanies from './pt/companies.json';
import esCompanies from './es/companies.json';

interface IPositions{
    name: string,
    desc: string
}

interface IExperience {
    name: string,
    time: string,
    positions: IPositions[]
}

export const translate  = (key: string, lang: string): string => {
    let langData: {[key: string]: string} = {};

    if(lang === 'EN')
        langData = en;
    else if(lang === 'PT')
        langData = pt;
    else if(lang === 'ES')
        langData = es;

    return langData[key];
}


export const translCompanies  = (lang: string): IExperience[] | undefined => {
    let langData: IExperience[] | undefined = undefined;

    if(lang === 'EN')
        langData = enCompanies;
    if(lang === 'PT')
        langData = ptCompanies;
    else if(lang === 'ES')
        langData = esCompanies;

    return langData;
}