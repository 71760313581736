const scrollActions = {
  touchstart: 0,
  handle: setTimeout(() => {}, 0),
  scrollY: 0,

  getNextOrPrevElment: () => {
    const sectionActive = document.querySelector("section.active");
    const sectionNext = sectionActive?.nextElementSibling;
    const sectionPrev = sectionActive?.previousElementSibling;

    return scrollActions.scrollY > 5
      ? sectionNext?.localName === "section" && sectionNext.id
      : scrollActions.scrollY < -5 &&
          sectionPrev?.localName === "section" &&
          sectionPrev.id;
  },

  setNavItemActive: (item: string) => {
    document.querySelectorAll(".navbar li").forEach((el) => {
      el.classList.remove("active");
    });
    document.querySelector(`[class*="${item}"]`)?.classList.add("active");
  },

  changeHomeView: (position: string) => {
    const sections = document.querySelectorAll("section");

    sections.forEach((el) => {
      el.classList.remove("active");
    });

    document.querySelector(`#${position}`)?.classList.add("active");

    position === "intro"
      ? scrollActions.setNavItemActive("home")
      : scrollActions.setNavItemActive(`${position}`);

    window.screenTop = 0;
  },

  scrollEvents(action: any) {
    window.addEventListener("wheel", (e) => action(e.deltaY));

    window.addEventListener(
      "touchstart",
      (e) => (this.touchstart = e.changedTouches[0].pageY),
      false
    );
    window.addEventListener(
      "touchend",
      (e) => {
        const touchend = e.changedTouches[0].pageY;
        if (this.touchstart && touchend) action(this.touchstart - touchend);
      },
      false
    );
  },
};

export default scrollActions;
